<template>
  <vs-card>
    <div slot:header class="flex flex-no-wrap w-full justify-end items-center px-5" vs-w="12">
      <!-- <h4>Organisations</h4> -->
      <div class="mr-2">
        <assign-staff v-if="canAssignClinic" @onSuccess="getOrganizationList" :userId="this.dataTableParams.id" />
        <vs-button v-if="canCreate" @click="redirectToCreateOrg">Create</vs-button>
      </div>
      <!-- <div
              class="mr-2"
              v-if="
                $store.state.AppActiveUser.userType === 'superAdmin' ||
                ($store.state.AppActiveUser.userType === 'admin' &&
                  canCreate &&
                  !franchiseId)
              "
            >
              <vs-button
                @click="onboardNurseHandler"
                icon-pack="feather"
                icon="icon-edit"
                >Onboard Nurse</vs-button
              >
            </div> -->
    </div>
    <div class="p-5">
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-no-wrap">
            <div class="w-full pr-1">
              <vs-input icon="search" style="width: 100%" placeholder="Search" v-model="searchInputString" />
            </div>
            <!-- <div class="pl-1" style="width: 14.5rem">
            <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in filterList"
              />
            </vs-select>
          </div> -->
            <div class="w-24 pl-1">
              <vs-select width="100%" placeholder="10" autocomplete v-model="dataTableParams.limit">
                <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in limitOptions" />
              </vs-select>
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div class="rounded mb-2 p-2 mobile-text" style="border: 1px solid #e8e8e8">
                <div class="pb-2">
                  <h4 class="mr-2 mobile-text">
                    {{
                        users[indextr].name
                          ? users[indextr].name
                          : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div lass="flex pb-2">
                  <h4 class="mobile-text">
                    {{ users[indextr].abn }}
                  </h4>
                </div>
                <div>
                  <p>
                    {{ users[indextr].status ? "Approved" : "" }}
                  </p>
                </div>
                <div class="flex">
                  <vs-button v-if="check_has_permission('updateOrganizationManagement')" style="float: left"
                    size="small" type="border" @click="redirectToDetailsOrg(users[indextr]._id)" class="m-1">
                    Edit</vs-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <vs-table class="hidden lg:block" ref="table" :sst="true" :total="totalDocs" :data="users"
          @search="handleSearch" @change-page="handleChangePage" @sort="handleSort" :max-items="dataTableParams.limit"
          search :noDataText="noDataText">
          <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
            <div>
              <vs-select width="100%" placeholder="10" autocomplete v-model="dataTableParams.limit">
                <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in limitOptions" />
              </vs-select>
              <!-- <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in filterList"
              />
            </vs-select> -->
            </div>
          </div>

          <template slot="thead">
            <vs-th sort-key="orgname">Organisation Name</vs-th>
            <vs-th sort-key="abn" v-if="isAU">ABN</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].name">{{
                  data[indextr].name | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].abn" v-if="isAU">{{ data[indextr].abn }}</vs-td>
              <vs-td :data="data[indextr].status">{{
                  data[indextr].status ? "Approved" : ""
              }}</vs-td>
              <vs-td :data="data[indextr]._id">
                <vs-button style="float: left" size="small" type="border"
                  @click="redirectToDetailsOrg(data[indextr]._id)" class="m-1">
                  Edit</vs-button>
                <vs-button style="float: left" size="small" type="border" @click="removeHandler(data[indextr])"
                  class="m-1">
                  Remove</vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
                dataTableParams.page * dataTableParams.limit -
                (dataTableParams.limit - 1)
            }}
            -
            {{
                totalDocs - dataTableParams.page * dataTableParams.limit > 0
                  ? dataTableParams.page * dataTableParams.limit
                  : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate :page-count="totalPage" :click-handler="handleChangePage" class="pagination" :page-range="9"
              :prevText="'<'" :nextText="'>'"></paginate>
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import AssignStaff from "./assignStaff.vue";

export default {
  name: "organization-table",
  components: {
    AssignStaff,
  },
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: null,
    },
    viewRoute: {
      type: String,
      default: "FranchiseNurseDetails",
    },
    viewDetailRoute: {
      type: String,
      default: "SuperAdminOrganizationDetails",
    },
    orgCreateRoute: {
      type: String,
      default: "SuperAdminOrganizationCreate",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: true,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      orgCount: 0,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        filter: "All",
        id: "",
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      filterList: [
        { value: "All", label: "All Nurses" },
        { value: "Approved", label: "Approved" },
        { value: "Unapproved", label: "Unapproved" },
      ],
      awaitingSearch: null,
      isAU: process.env.VUE_APP_REGION === 'AU'
    };
  },
  methods: {
    ...mapActions("organization", [
      "fetchAssociatedOrganizations",
      "fetchOrganizationUserRoles",
    ]),
    ...mapActions("orgOwner", ["removeStaffs"]),
    ...mapActions("basicUser", ["updateBasicUserIsSupplier"]),
    async getOrganizationList () {
      this.$vs.loading();
      const res = await this.fetchAssociatedOrganizations(this.dataTableParams)
        this.$vs.loading.close();
        this.noDataText = "No Organization Available";
        this.serverResponded = true;
        this.users = res.data.data.docs;
        try {
          this.totalDocs =
            res.data.data.pagination.total || res.data.data.pagination[0].total;
          this.page =
            res.data.data.pagination.page || res.data.data.pagination[0].page;
          this.currentPage =
            res.data.data.pagination.page || res.data.data.pagination[0].page;
        } catch (error) {
            console.error(error);
        }
        if (res.data.data && res.data.data.orgCount) {
          if (
            this.$store.state.AppActiveUser.userType === "franchise" ||
            this.$store.state.AppActiveUser.userType === "subFranchise"
          )
            this.orgCount = res.data.data.orgCount;
          if (
            this.$store.state.AppActiveUser.userType === "clinic" ||
            this.$store.state.AppActiveUser.userType === "subClinic"
          )
            this.orgCount = 1;
        }
        return res.data.data.docs 
    },
    async removeHandler(selectedOrg) {
      let userId = this.dataTableParams.id;
      const role = await this.fetchOrganizationUserRoles({
        organizationId: selectedOrg._id,
        userId: userId,
      });
      if (role.data.data[0].role === "Org Owner") {
        this.$vs.notify({
          title: "Remove failed",
          text: "Org Owner cannot be removed.",
          color: "danger",
        });
      } else {
        const res = await this.removeStaffs({
          organizationId: selectedOrg._id,
          userId: userId,
        })
       const organizationList = await this.getOrganizationList();
       if ( organizationList.length === 0 ){
        await this.updateBasicUserIsSupplier({ id : userId, isSupplier: false})
       }
        this.$vs.notify({
            title: "Staff removed",
            text: "Staff successfully removed.",
            color: "success",
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Remove failed",
              color: "danger",
            });
          });
      }
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getOrganizationList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getOrganizationList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getOrganizationList();
    },
    redirectToCreateOrg() {
      this.$router.push({
        name: this.orgCreateRoute,
      });
    },
    redirectToDetailsOrg(id) {
      this.$router.push({
        name: this.viewDetailRoute,
        params: {
          organizationId: id,
        },
      });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    nurseDetails: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getOrganizationList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getOrganizationList();
      }
    },
    // "$store.state.AppActiveClinicId": function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.dataTableParams.activeClinicId = newVal;
    //     // this.getOrganizationList();
    //   }
    // },
  },
  async created() {
    this.dataTableParams.id = this.userId;
    // if (this.franchiseId && this.franchiseId !== "") {
    //   this.dataTableParams.franchiseId = this.franchiseId;
    // }
    this.getOrganizationList();
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
}
</style>
