<template>
  <div id="data-list-list-view" class="data-list-container px-5">
    <vs-button
      @click="getOwner"
      id="openAssign"
      icon-pack="feather"
      icon="icon-edit"
      >Assign</vs-button
    >
    <vs-popup :title="'Assign Organization'" :active.sync="popupActive">
      <div>
        <vs-select
          placeholder="Select Organization"
          label="Names"
          name="organizationSelect"
          autocomplete
          v-model="orgId"
          class="w-full m-2"
        >
          <vs-select-item
            :key="index"
            :value="item._id"
            :text="item.name"
            v-for="(item, index) in organizations"
          />
        </vs-select>

        <vs-button
          @click="assignClinic"
          color="primary"
          class="m-2"
          type="border"
          id="assignOrg"
          >Assign</vs-button
        >
      </div>

      <!-- <div v-else>
        <vs-alert color="warning">Further clinics are not available.</vs-alert>
      </div> -->
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "assign-owner",
  props: {
    userId: {
      type: String,
      default: null,
    },
    // associatedIds: {
    //   type: Array,
    // },
  },
  data() {
    return {
      popupActive: false,
      organizations: [],
      orgId: "",
      showSelect: true,
    };
  },
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationAssignList",
      "assignOrganizationStaff",
    ]),
    getOwner() {
      this.popupActive = true;
      this.getOrganizationList(this.userId);
    },
    async getOrganizationList(id) {
      this.fetchOrganizationAssignList({ id: id }).then((res) => {
        this.organizations = res.data.data;
      });
      this.serverResponded = true;
    },
    async assignClinic() {
      try {
        const res = await this.assignOrganizationStaff({
          orgId: this.orgId,
          userId: this.userId,
        });
        this.$emit("onSuccess");
        this.$vs.notify({
          title: "Owner Assigned",
          text: "Organisation staff successfully assigned.",
          color: "success",
        });
        this.popupActive = false;
      } catch (err) {
        console.log(err);
        if (err.status === 422) {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to assign staff. Try again!",
            color: "danger",
          });
        }
        if (err.status === 409) {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.errors.add({
            field: "email",
            msg: err.data.message,
          });
        }
      }
    },
  },
};
</script>
